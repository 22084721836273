import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import "./blog-post.scss";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section Markdown">
      {helmet || ""}
      <h1>{title}</h1>
      <PostContent content={content} />
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter, html } = post || {};
  const { featuredimage, title, description } = frontmatter || {};

  return (
    <Layout>
      <header className="Layout__header">
        <PreviewCompatibleImage
          className="BlogPost__picture"
          imageInfo={{
            image: featuredimage,
            alt: `Image principale de ${title}`,
          }}
        />
      </header>
      <section className="Layout__body">
        <BlogPostTemplate
          content={html}
          contentComponent={HTMLContent}
          description={description}
          helmet={
            <Helmet titleTemplate="%s | Blog">
              <title>{title}</title>
              <meta name="description" content={description} />
            </Helmet>
          }
          title={title}
        />
      </section>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
